import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import { PageBanner } from "../../components/parts/section";
import styles from "./style.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import {
  Button,
  SectionTitle,
  Text,
} from "../../components/styledComponent";
import { LineDivider } from "../../components/ui";
import { Book } from "react-bootstrap-icons";
import ownerImageOne from "../../assets/ownerImageOne.jpeg";
import ownerImageTwo from "../../assets/ownerImageTwo.jpeg";
import ownerImageThree from "../../assets/ownerImageThree.jpeg";
import franchiseImg from "../../assets/ownFranchise.jpeg";
import billoosClientVideo from "../../assets/billosClient.mp4";
import { FranchiseForm } from "../../components/parts";

const About = () => {
  const franchiseRef = useRef();
  return (
    <>
      <Helmet>
        <title>
          About Billoos Restaurant | Order Indian Food in Calgary NW
        </title>
        <meta
          name="description"
          content="Billoos Restaurant is premier Indain restaurant serving lunch & dinner menus at Calgary NW area with best quality & have elagance décor."
        />
      </Helmet>
      <div className={styles.aboutPage}>
        <PageBanner title={"About Billoos"} />

        {/* About First Section */}
        <div className={styles.aboutFirstSection}>
          <Container className={`sectionContainer`}>
            <Row>
              <Col>
                <SectionTitle>Billoos Restaurant</SectionTitle>
                <SectionTitle className={styles.subTitle}>
                  The Respect , Integrity , Enriching & Accomplish !
                </SectionTitle>
                <LineDivider center />
                <Text>
                  We bring rich Indian heritage food from the past to future
                  with the taste as passion , in Calgary . Billoo’s menu offers
                  a feast of contemporary Indian Cuisine . Billoo’s vision is
                  spiced by Gaauri & Sanjay who’ve served in customer service
                  industries from the past 20 years and their vision is to “Win
                  the Customer forever” and provide unforgettable dinning and
                  take out experience with Billoo’s .
                </Text>
                <div className={styles.btnContainer}>
                  {/* <Anchor href={"#"}>Article</Anchor> */}
                  {/* <Anchor href={"#"}>Own a franchise</Anchor> */}
                  <Button onClick={() => franchiseRef.current.scrollIntoView()}>
                    Own a franchise
                  </Button>
                </div>
                <div className={styles.ourStorySection}>
                  <div className={styles.ourStoryCol}>
                    <div>
                      <Book color="var(--secondaryColor)" size={80} />
                    </div>
                    <div>
                      <SectionTitle>Our Story</SectionTitle>
                      <LineDivider center />
                    </div>
                    <div>
                      <Text>
                        We bring rich Indian heritage food from the past to
                        future with the taste as passion , in Calgary . Billoo’s
                        menu offers a feast of contemporary Indian Cuisine.
                      </Text>
                    </div>
                  </div>
                  <div className={styles.ourStoryCol}>
                    <div>
                      <svg
                        version="1.1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 256 256"
                        enable-background="new 0 0 256 256"
                      >
                        <metadata>
                          {" "}
                          Svg Vector Icons : http://www.onlinewebfonts.com/icon{" "}
                        </metadata>
                        <g>
                          <g>
                            <g>
                              <path
                                fill="#fff"
                                d="M11.4,20.1L10,21.4v93.5v93.5l1.3,1.2c1,1,11,3.4,59.1,14.5l57.9,13.3l57.9-13.4c49.7-11.5,58-13.5,58.8-14.6c0.9-1.1,1-6,1-94.6V21.4l-1.4-1.3c-0.7-0.8-1.8-1.4-2.5-1.4c-0.6,0-26.5,5.9-57.6,13.1l-56.5,13.1L71.5,31.7c-31.1-7.2-57.1-13.1-57.7-13.1C13.2,18.7,12.1,19.3,11.4,20.1z M71.9,40.8l51.7,11.9v87.4c0,69.7-0.2,87.3-0.7,87.1c-0.4-0.2-24-5.6-52.4-12.2l-51.8-12v-87.4c0-69.7,0.2-87.3,0.7-87.1C19.8,28.8,43.4,34.3,71.9,40.8z M237.3,115.8v87.3l-51.7,12c-28.5,6.6-52.1,12-52.4,12.2c-0.5,0.2-0.7-17.5-0.7-87.1V52.7l52.1-12c28.6-6.7,52.2-12.1,52.4-12.1C237.1,28.5,237.3,67.8,237.3,115.8z"
                              />
                              <path
                                fill="#fff"
                                d="M37.8,61.2c-0.9,0.9-1.6,2-1.6,2.5c0,1.4,1.5,4,2.6,4.3c3,0.9,62.9,14.5,64,14.5c1.8,0,3.9-2.3,3.9-4.3c0-3.6,0.5-3.4-33.8-11.3c-17.5-4-32.2-7.4-32.7-7.4C39.8,59.7,38.7,60.4,37.8,61.2z"
                              />
                              <path
                                fill="#fff"
                                d="M37.6,84.5c-0.8,0.8-1.4,2-1.4,2.9c0,3.6-0.7,3.4,34,11.4c20.8,4.8,32.6,7.3,33.4,7c2.8-0.7,3.8-5.6,1.6-7.5c-0.8-0.7-62.9-15.2-65.1-15.2C39.4,83.1,38.3,83.8,37.6,84.5z"
                              />
                              <path
                                fill="#fff"
                                d="M38,107.2c-0.7,0.5-1.3,1.6-1.5,2.2c-0.5,1.5,0.6,4.1,2.1,4.8c1.4,0.7,62.7,14.9,64.4,14.9c1.6,0,3.8-2.4,3.8-4.3c0-3.7,0.7-3.4-33.6-11.4c-17.4-4-32.2-7.3-32.8-7.3C39.7,106.1,38.6,106.6,38,107.2z"
                              />
                              <path
                                fill="#fff"
                                d="M37.6,131c-2.7,2.6-1.2,6.2,2.9,7.3c1.1,0.3,15.5,3.7,32,7.4c26.1,6,30.2,6.8,31.5,6.3c3-1.2,3.5-5.7,0.9-7.3c-1.3-0.8-62.4-15.1-64.6-15.1C39.5,129.6,38.3,130.2,37.6,131z"
                              />
                              <path
                                fill="#fff"
                                d="M37.8,157.4c-0.9,0.9-1.6,2-1.6,2.5c0,1.4,1.5,4,2.6,4.3c3,0.9,62.9,14.5,64,14.5c1.8,0,3.9-2.3,3.9-4.3c0-3.6,0.6-3.4-33.8-11.3c-17.6-4-32.3-7.4-32.7-7.4C39.8,155.8,38.7,156.5,37.8,157.4z"
                              />
                              <path
                                fill="#fff"
                                d="M37.6,180.7c-0.8,0.8-1.4,2-1.4,2.9c0,3.6-0.7,3.4,34,11.4c20,4.6,32.6,7.3,33.4,7c2.7-0.7,3.8-5.7,1.6-7.5c-0.8-0.7-62.9-15.2-65.1-15.2C39.4,179.3,38.3,179.9,37.6,180.7z"
                              />
                              <path
                                fill="#fff"
                                d="M184.8,66.7c-16.4,3.8-30.4,7.2-31.3,7.3c-3.6,0.9-4.8,4.7-2.3,7.2c0.7,0.8,1.9,1.4,2.6,1.4c2.2,0,63.5-14.3,64.7-15.1c3.4-2.2,1.1-8.1-3-7.8C215,59.8,201.2,62.9,184.8,66.7z"
                              />
                              <path
                                fill="#fff"
                                d="M184,90.3c-16.8,3.9-31.1,7.4-31.8,7.7c-1.5,0.7-2.6,3.3-2.1,4.8c0.5,1.6,2.5,3.3,3.8,3.3c0.7,0,15.4-3.3,32.8-7.3c34.3-7.9,33.6-7.7,33.6-11.4c0-2.1-2.2-4.3-4.2-4.2C215.3,83.2,200.8,86.4,184,90.3z"
                              />
                              <path
                                fill="#fff"
                                d="M183.2,113.5c-33.9,7.9-33.3,7.6-33.3,11.3c0,1.9,2.1,4.3,3.8,4.3c1,0,61-13.6,64.1-14.5c1.3-0.4,2.7-3.3,2.3-4.9c-0.5-2-2.3-3.5-4-3.4C215.2,106.1,200.4,109.5,183.2,113.5z"
                              />
                              <path
                                fill="#fff"
                                d="M183,137c-32.6,7.6-32.1,7.4-32.9,10.5c-0.3,1.5,1,3.8,2.8,4.5c1,0.5,7.1-0.8,31.2-6.3c36.1-8.3,33.9-7.8,35.2-9.1c2.5-2.5,0.2-7.1-3.4-7C214.9,129.7,200.1,133,183,137z"
                              />
                              <path
                                fill="#fff"
                                d="M184.8,162.9c-16.4,3.8-30.4,7.1-31.2,7.3c-2.1,0.5-3.7,2.5-3.7,4.3c0,1.9,2.2,4.3,3.9,4.3c2.2,0,63.5-14.3,64.7-15.1c3.4-2.2,1.1-8.1-3-7.8C215,155.9,201.2,159,184.8,162.9z"
                              />
                              <path
                                fill="#fff"
                                d="M184,186.5c-16.8,3.9-31.1,7.4-31.8,7.7c-1.5,0.7-2.6,3.3-2.1,4.8c0.5,1.6,2.5,3.3,3.8,3.3c0.7,0,15.4-3.3,32.8-7.3c34.3-7.9,33.6-7.7,33.6-11.4c0-2.1-2.2-4.3-4.2-4.2C215.3,179.4,200.8,182.6,184,186.5z"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div>
                      <SectionTitle>Discover Our Menu</SectionTitle>
                      <LineDivider center />
                    </div>
                    <div>
                      <Text>
                        For those with pure food indulgence in mind, come next
                        door and sate your desires with our ever changing
                        internationally and seasonally inspired small plates. We
                        love food, lots of different food, just like you.
                      </Text>
                    </div>
                  </div>
                  <div className={styles.ourStoryCol}>
                    <div>
                      <svg
                        version="1.1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 256 256"
                        enable-background="new 0 0 256 256"
                      >
                        <metadata>
                          {" "}
                          Svg Vector Icons : http://www.onlinewebfonts.com/icon{" "}
                        </metadata>
                        <g>
                          <g>
                            <g>
                              <path
                                fill="#fff"
                                d="M127.4,19.4c-3.5,1.4-5.8,3.2-7.6,5.7c-1.6,2.2-2.8,5.9-2.8,8.6c0,4.2,3.5,10,7.5,12.5c1.1,0.7,1.7,1.4,1.7,2.1c0,1-0.3,1.2-3.2,1.4c-8.8,0.7-20,4-29.4,8.5c-24,11.6-41.7,32.4-48.9,57.6c-1.8,6.2-3.2,14.4-3.2,18.4c0,4.2,0.6,4-9.8,4c-6.6,0-9.4,0.2-9.8,0.6c-0.8,0.8-0.7,2.6,0.1,3.2c0.9,0.8,222.7,0.8,223.6,0c0.8-0.7,0.9-2.5,0.1-3.2c-0.4-0.4-3.3-0.6-9.9-0.6h-9.3l-0.2-1.6c-0.1-0.8-0.4-3.3-0.5-5.4c-1.7-22.3-14.6-46.1-33.2-61.2c-13.9-11.2-32.2-19-48-20.3c-3-0.2-3.3-0.4-3.3-1.4c0-0.7,0.5-1.4,1.6-2.1c0.9-0.6,2.4-1.9,3.4-2.9c6.9-7.6,4.8-18.1-4.6-23.1c-2.6-1.4-3.6-1.6-7.1-1.8C130.8,18.2,129.7,18.4,127.4,19.4z M139.3,24.2c4.3,2,6.3,5.4,5.9,10.2c-0.3,3.6-2.2,6.2-5.9,8.1l-2.9,1.5l-0.1,4.4c-0.1,5.2-0.2,5.1,5.4,5.6c23.5,2.1,46.2,14.2,60.7,32.3c8.3,10.3,14.5,23.6,17.1,36.1c1.1,5.4,2.1,14.5,1.7,15.2c-0.1,0.2-36.1,0.4-87.6,0.4s-87.5-0.2-87.6-0.4c-0.4-0.7,0.6-9.8,1.7-15.2c3.7-18.4,14.3-36,28.9-48.2C90,63,107.9,55.6,125.4,54.1c5.7-0.5,5.6-0.4,5.4-5.6l-0.1-4.4l-2.9-1.5c-1.9-1-3.4-2.2-4.3-3.6c-1.3-1.8-1.4-2.5-1.4-5.6s0.1-3.8,1.4-5.6C126.6,23.3,133.8,21.6,139.3,24.2z"
                              />
                              <path
                                fill="#fff"
                                d="M151.4,64.1c-0.6,0.4-0.8,1.1-0.7,2c0.1,1.3,0.8,1.7,6.9,4.8c10.7,5.4,19.2,11.7,26.5,19.7c9.2,10,14,19,17.1,32c0.4,1.6,0.8,2.1,1.8,2.4c1.9,0.5,3.2-1.3,2.7-3.6c-2.9-16.2-15.1-33.6-32.2-46.3c-5.5-4-9-6.1-15.2-9.2C152.8,63.3,152.6,63.2,151.4,64.1z"
                              />
                              <path
                                fill="#fff"
                                d="M105.1,163.7c-24.4,0.4-25.3,0.6-41.7,7.1c-3.3,1.3-6.7,2.6-7.6,2.7c-1.4,0.3-1.5,0.2-1.5-1.1c0-1.9-1.1-3-3-3c-0.9,0-4.5,0.4-8,1c-3.6,0.6-12.2,1.7-19.2,2.6c-6.9,0.9-13,1.9-13.3,2.2c-0.6,0.5-0.7,4.9-0.7,31.1c0,27.3,0.1,30.5,0.8,31.1c0.6,0.5,3.2,0.3,15.5-1.4c17.4-2.4,24.9-3.6,32.3-5c6.4-1.2,7.1-1.7,6.4-5c-0.9-4-0.7-4.7,2.4-7.6c1.6-1.5,3.5-3,4.2-3.3c1-0.3,8.7-0.3,26.9,0c26.8,0.6,38.5,0.4,43-0.8c3.3-0.9,5.7-2.1,23.7-12.6c32.1-18.5,33.4-19.5,35-23.1c1.6-3.7,0-8.1-3.4-9.7c-3.9-1.9-7.8-1.3-19.8,3.2c-4,1.4-11.6,4.1-16.9,6c-5.3,1.8-11.8,4-14.5,5c-5.5,2-7,2.1-17.8,1c-3.9-0.4-8.3-0.7-9.6-0.7c-3.7,0-1.9-0.7,6.5-2.4c10.9-2.2,14.9-4.3,15.7-8.4c0.6-3.5-1.3-6.8-4.9-8.5c-1.5-0.7-3-0.9-6.5-0.8C126.5,163.4,115.7,163.5,105.1,163.7z M133.6,168c1.1,0,2.2,1.4,2.2,2.8c0,2.5-2.6,3.8-10,5.1c-2.3,0.4-9.2,2-15.5,3.5c-9.5,2.3-11.4,2.9-11.9,3.8c-0.7,1-0.7,1.3,0,2.3c0.8,1.3,0.3,1.2,17.7,2.5c5.2,0.4,12.2,0.9,15.8,1.3c7.6,0.6,11.4,0.2,16.9-2c2.1-0.8,9.3-3.4,16-5.7c6.7-2.4,15.1-5.2,18.5-6.5c4.6-1.6,7-2.2,8.9-2.2c3.1,0,3.9,0.5,3.9,2.7c0,2.3-1.8,3.9-7.9,7.5c-6.3,3.6-20.6,11.9-31.3,18.2c-4.2,2.5-9.6,5.4-11.9,6.6c-6.1,3.1-9,3.3-43.8,2.6c-32.2-0.7-31.3-0.7-35.5,3.1c-2.4,2.1-2.5,2.1-2.7,1.1c-1.2-4.9-7-34.9-6.9-35.5c0.1-0.4,1.7-1.2,3.5-1.8c1.9-0.6,6.5-2.3,10.3-3.8c11.8-4.7,11.4-4.6,38.1-5.1c12.8-0.2,23.4-0.5,23.6-0.7c0.1-0.1,0.6-0.1,1,0C132.6,167.9,133.2,168,133.6,168z M54.9,199.9c3.9,19.2,5,25.7,4.5,26c-0.6,0.4-5.6,1.1-5.8,0.8c-0.2-0.3-10.8-51.3-10.7-51.3c0.1-0.1,5-1,6.1-1.1C49.6,174.3,50.7,179.2,54.9,199.9z M43.5,201.6c2.9,14,5.3,25.6,5.3,25.9c0,0.4-29.2,4.9-32.6,5l-1.3,0v-26.7c0-14.7,0-26.7,0.1-26.8c0.5-0.1,20.7-2.7,21.8-2.8l1.4,0L43.5,201.6z"
                              />
                              <path
                                fill="#fff"
                                d="M34.8,219.3c-3.1,3,0.4,7.9,4.4,6.3c4.1-1.7,2.9-7.5-1.5-7.5C36.5,218.1,35.6,218.5,34.8,219.3z"
                              />
                              <path
                                fill="#fff"
                                d="M23.7,220c-1.3,0.5-3.1,2.7-3.1,3.9c0,0.4,0.6,1.5,1.3,2.3c1.5,1.9,3.9,2,5.7,0.4c1.9-1.7,2.1-3.3,0.4-5.1C26.4,219.9,25.1,219.5,23.7,220z M25.4,223.9c0,0.2-0.2,0.5-0.5,0.5c-0.2,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5C25.2,223.4,25.4,223.6,25.4,223.9z"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div>
                      <SectionTitle>We Cater For All Occassions</SectionTitle>
                      <LineDivider center />
                    </div>
                    <div>
                      <Text>
                        Billoo’s Catering is available to cater your off-site
                        event in the Calgary & near area. Whether it’s for a
                        corporate meeting for 200 delegates or a birthday party
                        for 10 guests, Billoo’s Catering’s creative culinary
                        options are sure to delight. We pride ourselves on
                        customizing menus to fit your budget and theme. Trust
                        Billoo’s Catering for your next event.
                      </Text>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Owner image section */}
        <div className={styles.ownerImageSection}>
          <Container>
            <Row>
              <Col xs={12} md={12} lg={4} className={styles.ownerImgCol}>
                <img src={ownerImageOne} alt="Owner one" />
              </Col>
              <Col xs={12} md={12} lg={4} className={styles.ownerImgCol}>
                <img src={ownerImageTwo} alt="Owner two" />
              </Col>
              <Col xs={12} md={12} lg={4} className={styles.ownerImgCol}>
                <img src={ownerImageThree} alt="Owner three" />
              </Col>
            </Row>
          </Container>
        </div>

        {/* Story section */}
        <div className={styles.storySection}>
          <Container className={`sectionContainer`}>
            <Row>
              <Col lg={6}>
                <SectionTitle>Billoo's Story</SectionTitle>
                <LineDivider />
                <Text>
                  We bring rich Indian heritage food from the past to future
                  with the taste as passion , in Calgary . Billoo’s menu offers
                  a feast of contemporary Indian Cuisine . Billoo’s vision is
                  spiced by Gaauri & Sanjay who’ve served in customer service
                  industries from the past 20 years and their vision is to “Win
                  the Customer forever” and provide unforgettable dinning and
                  take out experience with Billoo’s.
                  <br />
                  <br />
                  BILLOO’S currently boasts 2 location in Calgary AB and we are
                  constantly expanding towards new horizons. The current trend
                  which balancing the high level of choice of recipe from
                  Billoo’s Authentic Food for health and affordability reasons
                  combined with the Billoo’s concept is undoubtedly a recipe for
                  success.
                </Text>
              </Col>
              <Col lg={6}>
                <video height="240" controls>
                  <source src={billoosClientVideo} type="video/mp4" />
                </video>
              </Col>
            </Row>
            <Row>
              <Col>
                <Text>
                  A dedicated team is the driving force behind the operations of
                  this specialist franchise company and this approach of hard
                  work and passion corroborates an ideal business partner for
                  the novice in the food industry. Full operational and
                  managerial training are the pillars of this enterprising
                  company. On going product and systems development supports
                  this concept of franchising and makes for a profitable Modern
                  Indian Cuisine Restaurant concept.
                  <br />
                  <br />
                  The BILLOO’S range of Variety of Non-Veg / Veg Food Selection
                  known for their uniqueness, quality and generous portions, and
                  promote return visits to the restaurant . As important as the
                  Billoo’s to be the Pioneer in the Industry by Using the finest
                  quality of Halal Meat with Freshness of Vegetable and maintain
                  the authentic Indian Essential Taste to bring it for ours
                  Customers.
                </Text>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Own a franchise */}
        <div className={styles.ownaFranchise}>
          <Container className={"sectionContainer"}>
            <Row>
              <Col lg={12}>
                <SectionTitle>Continued Growth and Expanding</SectionTitle>
                <LineDivider center />
                <Text>
                  Our clients love our food, which allowed us to grow our
                  business and expand to various areas of the city. We are
                  currently service the NW and SW area of Calgary with 2
                  locations. We are planning to expand even more with our
                  Franchise Partner in the future.
                </Text>
                <Text>
                  <b>
                    For Canadian Franchise inquires please send us an email at{" "}
                    <a
                      href="mailto:franchise@billoos.com"
                      style={{ color: "var(--accentColor)" }}
                    >
                      franchise@billoos.com
                    </a>
                  </b>
                </Text>
              </Col>
            </Row>
            <Row ref={franchiseRef}>
              <Col lg={6}>
                <SectionTitle>Own a Franchise</SectionTitle>
                <LineDivider />
                <FranchiseForm />
              </Col>
              <Col lg={6}>
                <img src={franchiseImg} alt="Own a franchise" />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default About;
