import React from "react";
import { easeInOut, motion } from "framer-motion";
import { Container, Row, Col } from "react-bootstrap";
import { Anchor, SectionTitle } from "../../components/styledComponent";
import { CateringForm } from "../../components/parts";
import styles from "./style.module.scss";
import { Helmet } from "react-helmet";
import { LineDivider } from "../../components/ui";

const Catering = () => {
  return (
    <>
      <Helmet>
        <title>CATERING - Billoos Restaurant Calgary</title>
      </Helmet>
      <div className={styles.contactPage}>
        {/* Contact section start */}
        <section className={styles.contactSection}>
          <Container
            fluid
            className={`sectionContainer ${styles.reserveInnerSection}`}
          >
            <Row>
              <Col lg={12} className={styles.contactBlock}>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ delay: 0.5, easings: easeInOut }}
                >
                  <p className={styles.smallTxt}>Get A Quote</p>
                  <SectionTitle>Catering</SectionTitle>
                  <LineDivider />
                  <Anchor
                    href={
                      "https://pub-98ed85791a1f4c11b1319b39903a06f6.r2.dev/billoos%20catering%20deals.pdf"
                    }
                    target="_blank"
                  >
                    Get Catering Deals
                  </Anchor>
                  <CateringForm />
                </motion.div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};

export default Catering;
