import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { SectionTitle, Text } from "../../styledComponent";
import {
  TelephoneFill,
  Facebook,
  Instagram,
  Envelope,
} from "react-bootstrap-icons";
import { footerNavMenuItems } from "../../../utils/constants";
import footerLogo from "../../../assets/logo.png";
import styles from "./style.module.scss";
import { routes } from "../../../utils/routes";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row className={styles.footerInner}>
          <Col lg={3} className={styles.footerColOne}>
            <div className={styles.footerSiteLogo}>
              <img src={footerLogo} alt="Billoos Restaurant" />
            </div>
            <Text>
              Billoo’s Indian Kitchen , a local gem serving to the community
              from the last 6 years in Northwest Area and now expending wings to
              serving the NORTH EAST Community as well with offer a delightful
              culinary journey with authentic Indian flavours, diverse menu and
              warm hospitality. Must -Try out !
            </Text>
          </Col>
          <Col lg={4} className={styles.footerColThree}>
            <SectionTitle>Working Hours</SectionTitle>
            <div className={styles.hoursBlock}>
              <div>
                <Text>MONDAY</Text>
                <Text>CLOSED</Text>
              </div>
              <div>
                <Text>TUESDAY - THURSDAY</Text>
                <Text>04:00 PM – 09:00 PM</Text>
              </div>
              <div>
                <Text>FRIDAY - SATURDAY</Text>
                <Text>04:00 PM – 09:30 PM</Text>
              </div>
              <div>
                <Text>SUNDAY</Text>
                <Text>11:30 AM – 09:00 PM</Text>
              </div>
            </div>
          </Col>
          <Col lg={2} className={styles.footerColTwo}>
            <SectionTitle>Navigation</SectionTitle>
            <ul className={styles.footerProductList}>
              {footerNavMenuItems.map((val, i) => {
                return (
                  <li key={i}>
                    <a href={val.link}>
                      <span>{val.name}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col lg={3} className={styles.footerColFour}>
            <SectionTitle>Contact Us</SectionTitle>
            <ul className={styles.contactDetailsBox}>
              <li>
                <a href="mailto:info@billoos.com">
                  <Envelope />
                  <span>info@billoos.com</span>
                </a>
              </li>
              <li>
                <a href="tel:4037706111">
                  <TelephoneFill />
                  <span>(403) 770-6111</span>
                </a>
              </li>
            </ul>
            <div className={styles.socialIconBox}>
              <div>
                <a href={routes.FB}>
                  <Facebook size={25} />
                </a>
                <a href={routes.INSTAGRAM}>
                  <Instagram size={25} />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className={styles.bottomFooter}>
        <Row>
          <Col lg={6}>
            <Text>Copyright © 2024 Billoo's Restaurant</Text>
          </Col>
          <Col lg={6}>
            <Text>
              Website Design and SEO by{" "}
              <a
                href="https://x360digital.com/"
                target="_blank"
                rel="noreferrer"
              >
                x360 Digital Inc.
              </a>
            </Text>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
