import React from "react";
import { Helmet } from "react-helmet";
import { PageBanner } from "../../components/parts/section";
import styles from "./style.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import { SectionTitle } from "../../components/styledComponent";
import { LineDivider, ServicePriceBox } from "../../components/ui";
import { appetizerPriceListData, priceListData } from "../../utils/constants";

const TakeOutMenu = () => {
  return (
    <>
      <Helmet>
        <title>
          About Billoos Restaurant | Order Indian Food in Calgary NW
        </title>
        <meta
          name="description"
          content="Billoos Restaurant is premier Indain restaurant serving lunch & dinner menus at Calgary NW area with best quality & have elagance décor."
        />
      </Helmet>
      <div className={styles.takeOutPage}>
        <PageBanner title={"Take Out Menu"} />

        {/* Menu First Section */}
        <div className={styles.menuFirstSection}>
          <Container className={`sectionContainer`}>
            {/* <Row>
              <Col>
                <SectionTitle>Billoos Restaurant</SectionTitle>
                <SectionTitle className={styles.subTitle}>
                  The Respect , Integrity , Enriching & Accomplish !
                </SectionTitle>
                <LineDivider center />
              </Col>
            </Row> */}
            <Row>
              <Col lg={12}>
                {appetizerPriceListData &&
                  appetizerPriceListData?.map((data, i) => (
                    <ServicePriceBox
                      key={i}
                      title={data?.title}
                      priceData={data?.priceData}
                    />
                  ))}
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <SectionTitle>MAINS</SectionTitle>
                <LineDivider center />
              </Col>
              <Col lg={12}>
                {priceListData &&
                  priceListData?.map((data, i) => (
                    <ServicePriceBox
                      key={i}
                      title={data?.title}
                      priceData={data?.priceData}
                    />
                  ))}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default TakeOutMenu;
