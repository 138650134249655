import React from "react";
import "./style.scss";

const BurgerMenu = (props) => {

  return (
    <>
      <input
        type="checkbox"
        id="checkbox"
        onChange={() => {
          if (props.isOpen) {
            props.open(false);
          } else {
            props.open(true);
          }
        }}
        checked={props.isOpen}
      />
      <label htmlFor="checkbox" className="toggle">
        <div className="bars" id="bar1"></div>
        <div className="bars" id="bar2"></div>
        <div className="bars" id="bar3"></div>
      </label>
    </>
  );
};

export default BurgerMenu;
