import React from "react";

const LineDivider = (props) => {
  const style = {
    borderTop: `4px solid ${props.color ? props.color : "var(--accentColor)"}`,
    opacity: "1",
    width: "100%",
    maxWidth: props.width ? props.width : "80px",
    margin: props.center
      ? "2rem auto"
      : props.right
      ? "2rem 0 2rem auto"
      : "2rem 0",
  };
  return <hr style={style} />;
};

export default LineDivider;
