import React from "react";
// import React, { useRef } from "react";
import { useFormik } from "formik";
// import ReCAPTCHA from "react-google-recaptcha";
import styles from "./style.module.scss";
import { contactFormInitialValues } from "../../../utils/formik/initialvalue";
import { contactFormValidationSchema } from "../../../utils/formik/validationSchema";
import { Button } from "../../styledComponent";

const ContactForm = () => {
  // const recaptchaRef = useRef();
  const formik = useFormik({
    initialValues: contactFormInitialValues,
    validationSchema: contactFormValidationSchema,
  });

  console.log("is-valid", formik.isValid);
  console.log("is-dirty", formik.dirty);
  return (
    <div className={styles.formBlock}>
      <form
        // onSubmit={formik.handleSubmit}
        method="POST"
        name="contactForm"
        className="contactForm"
        netlify
        data-netlify="true"
        // data-netlify-recaptcha="true"
      >
        <input type="hidden" name="form-name" value="contactForm" />
        <div className={styles.fieldContainer}>
          <input
            type="text"
            {...formik.getFieldProps("name")}
            placeholder="Full Name*"
            style={
              formik.touched.name && formik.errors.name
                ? { borderColor: "red" }
                : null
            }
          />
        </div>
        <div className={styles.fieldContainer}>
          <input
            type="email"
            {...formik.getFieldProps("email")}
            placeholder="Email Address*"
            style={
              formik.touched.email && formik.errors.email
                ? { borderColor: "red" }
                : null
            }
          />
        </div>
        <div className={styles.fieldContainer}>
          <textarea
            {...formik.getFieldProps("message")}
            placeholder="Message"
            rows={8}
          />
        </div>
        {/* <div
          className={`${styles.fieldContainer} ${styles.recaptchaContainer}`}
        >
          <div data-netlify-recaptcha="true"></div>
        </div> */}
        <div className={styles.fieldContainer}>
          <Button
            className={
              !(formik.isValid && formik.dirty) ? "disabledBtn" : "submitBtn"
            }
            type="submit"
          >
            SUBMIT
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
