import * as Yup from "yup";

export const contactFormValidationSchema = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  // recaptcha: Yup.string().required(),
});

export const franchiseFormValidationSchema = Yup.object({
  name: Yup.string().required(),
  phone: Yup.string().required(),
  email: Yup.string().email().required(),
  Any_experience_in_food_industry: Yup.string().required(),
  have_you_ever_owned_a_restaurant_before: Yup.string().required(),
  // recaptcha: Yup.string().required(),
});

export const reservationFormValidationSchema = Yup.object({
  date: Yup.string().required(),
  time: Yup.string().required(),
  number_of_members: Yup.string().required(),
  name: Yup.string().required(),
  email: Yup.string().required(),
  phone: Yup.string().required(),
  // recaptcha: Yup.string().required(),
});

export const cateringFormValidationSchema = Yup.object({
  date: Yup.string().required(),
  time: Yup.string().required(),
  number_of_members: Yup.string().required(),
  name: Yup.string().required(),
  email: Yup.string().required(),
  phone: Yup.string().required(),
  // recaptcha: Yup.string().required(),
});
