import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { BurgerMenu } from "../../ui";
import MenuSlider from "../menuSlider";
import { NavText } from "../../styledComponent";
import { navMenuItems } from "../../../utils/constants";
import { Container, Row, Col } from "react-bootstrap";
import Logo from "../../../assets/logo.png";
import styles from "./style.module.scss";
import { ChevronDown } from "react-bootstrap-icons";
import { routes } from "../../../utils/routes";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  // const navigate = useNavigate();

  return (
    <header>
      {/* <div className={styles.topHeader}>
        <div className={styles.contactInfo}>
          <a href="https://maps.app.goo.gl/f3xaBTVmy7aM5Poz5">
            <Map size="1.8rem" />
            60 Sage Hill Plaza NW #107
          </a>
          <a href="tel:4037706111">
            <Telephone size="1.8rem" />
            <span>(403) 770-6111</span>
          </a>
        </div>
        <div className={styles.socialIcons}>
          <a href={routes.FB}>
            <Facebook />
          </a>
          <a href={routes.TWITTER}>
            <Twitter />
          </a>
          <a href={routes.INSTAGRAM}>
            <Instagram />
          </a>
        </div>
      </div> */}
      <div className={styles.bottomHeader}>
        <Container>
          <Row>
            <Col md={4} lg={3} className={styles.logoContainer}>
              <Link to={"/"}>
                <img src={Logo} alt="Your Massage" />
              </Link>
            </Col>
            <Col lg={9} className={styles.headerColTwo}>
              <ul className={styles.mainMenu}>
                {navMenuItems.map((item, i) =>
                  item.name === "About us" ? (
                    <NavText key={i} className={styles.innerChildList}>
                      <NavLink to={item.link}>
                        {item.name} <ChevronDown />
                      </NavLink>
                      <ul className={styles.subMenu}>
                        <li><a href={routes.BILLOOS_CORPORATE}>BILLOOS CORPORATE</a></li>
                      </ul>
                    </NavText>
                  ) : (
                    <NavText key={i}>
                      <NavLink to={item.link}>{item.name}</NavLink>
                    </NavText>
                  )
                )}
                {/* <li>
                  <Anchor href="tel:4037706111">
                    <Phone />
                    <span>(403)770-6111</span>
                  </Anchor>
                </li> */}
                {/* <li>
                  <Anchor href="https://billoosindiancuisine.orderingclub.com/en/billoosindiancuisinesagehill0">
                    <Phone />
                    <span>Order Online</span>
                  </Anchor>
                </li> */}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.mobileHeader}>
        <div>{/* <h1>hi</h1> */}</div>
        <div className={styles.mobileLogo}>
          <img src={Logo} alt="Your Massage" />
        </div>
        <div className={styles.burgerMenuContainer}>
          <BurgerMenu open={(val) => setIsOpen(val)} isOpen={isOpen} />
        </div>
        <MenuSlider closeMenu={(val) => setIsOpen(val)} isOpen={isOpen} />
      </div>
    </header>
  );
};

export default Header;
