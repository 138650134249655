import React from "react";
// import React, { useRef } from "react";
import { useFormik } from "formik";
// import ReCAPTCHA from "react-google-recaptcha";
import styles from "./style.module.scss";
import { cateringFormInitialValues } from "../../../utils/formik/initialvalue";
import { cateringFormValidationSchema } from "../../../utils/formik/validationSchema";
import { Button } from "../../styledComponent";

const CateringForm = () => {
  // const recaptchaRef = useRef();
  const formik = useFormik({
    initialValues: cateringFormInitialValues,
    validationSchema: cateringFormValidationSchema,
  });

  return (
    <div className={styles.formBlock}>
      <form
        // onSubmit={formik.handleSubmit}
        method="POST"
        name="cateringForm"
        className="cateringForm"
        netlify
        data-netlify="true"
        data-netlify-recaptcha="true"
      >
        <input type="hidden" name="form-name" value="cateringForm" />
        <div className={styles.fieldContainer}>
          <input
            type="date"
            {...formik.getFieldProps("date")}
            placeholder="Date*"
            style={
              formik.touched.date && formik.errors.date
                ? { borderColor: "red" }
                : null
            }
          />
          <input
            type="time"
            {...formik.getFieldProps("time")}
            placeholder="Time*"
            style={
              formik.touched.time && formik.errors.time
                ? { borderColor: "red" }
                : null
            }
          />
        </div>
        <div className={styles.fieldContainer}>
          <input
            type="text"
            {...formik.getFieldProps("number_of_members")}
            placeholder="Number of Members*"
            style={
              formik.touched.number_of_members &&
              formik.errors.number_of_members
                ? { borderColor: "red" }
                : null
            }
          />
        </div>
        <div className={styles.fieldContainer}>
          <input
            type="text"
            {...formik.getFieldProps("name")}
            placeholder="Name*"
            style={
              formik.touched.name && formik.errors.name
                ? { borderColor: "red" }
                : null
            }
          />
          <input
            type="text"
            {...formik.getFieldProps("phone")}
            placeholder="Phone Number*"
            style={
              formik.touched.phone && formik.errors.phone
                ? { borderColor: "red" }
                : null
            }
          />
        </div>
        <div className={styles.fieldContainer}>
          <input
            type="email"
            {...formik.getFieldProps("email")}
            placeholder="Email*"
            style={
              formik.touched.email && formik.errors.email
                ? { borderColor: "red" }
                : null
            }
          />
        </div>
        <div className={styles.fieldContainer}>
          <textarea
            {...formik.getFieldProps("message")}
            placeholder="Message"
            rows={8}
          />
        </div>
        {/* <div className={`${styles.fieldContainer} ${styles.recaptchaContainer}`}>
          <ReCAPTCHA
            ref={recaptchaRef}
            onChange={(value) => {
              formik.setFieldValue("recaptcha", value);
            }}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          />
        </div> */}
        <div className={styles.fieldContainer}>
          <Button
            className={
              !(formik.isValid && formik.dirty) ? "disabledBtn" : "submitBtn"
            }
            type="submit"
          >
            SUBMIT
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CateringForm;
