import { Text } from "../../styledComponent";
import styles from "./style.module.scss";

const PricingBox = ({ title, price, description }) => {
  return (
    <div className={styles.pricingBox}>
      <div className={styles.pricingLabel}>
        <h3>{title}</h3>
        <span></span>
        <Text>${price}</Text>
      </div>
      <Text>{description}</Text>
    </div>
  );
};

export default PricingBox;
