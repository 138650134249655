import React from "react";
import styles from "./style.module.scss";
import PricingBox from "../pricingBox";
import LineDivider from "../lineDivider";

const ServicePriceBox = (props) => {
  return (
    <div className={styles.servicePriceBox}>
      <div className={styles.serviceName}>
        <h3>{props.title}</h3>
        <LineDivider />
      </div>
      <div className={styles.servicePrice}>
        {props.priceData &&
          props.priceData?.map((data, i) => (
            <PricingBox
              key={i}
              title={data?.title}
              price={data?.price}
              description={data?.description}
            />
          ))}
      </div>
    </div>
  );
};

export default ServicePriceBox;
