import React from "react";
import { createPortal } from "react-dom";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import styles from "./style.module.scss";
import { mobileNavMenuItems } from "../../../utils/constants";
import { Button } from "../../styledComponent";
import { routes } from "../../../utils/routes";

const MenuSlider = (props) => {
  // const navigate = useNavigate();
  const show = {
    scaleX: 1,
    transformOrigin: "right",
  };

  const hide = {
    scaleX: 0,
    transformOrigin: "right",
    transitionEnd: {
      scaleX: 0,
    },
  };

  // Dont allow user to scroll when popup is active
  // useEffect(() => {
  //   if (props.isOpen) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "unset";
  //   }
  // }, [props]);

  return (
    <>
      {props.isOpen &&
        createPortal(
          <div className="popupBgLayer"></div>,
          document.getElementById("modalOverlay")
        )}
      <motion.div
        animate={props.isOpen ? show : hide}
        transition={{ duration: 0.5 }}
        className={`${styles.menuSliderContainer} mobileMenuBlock`}
      >
        <ul>
          {mobileNavMenuItems.map((item, i) => (
            <motion.li
              key={i}
              animate={props.isOpen ? { opacity: 1 } : { opacity: 0 }}
            >
              <NavLink to={item.link} onClick={() => props.closeMenu(false)}>
                {item.name}
              </NavLink>
            </motion.li>
          ))}

          <motion.li
            animate={props.isOpen ? { opacity: 1 } : { opacity: 0 }}
            style={{ textAlign: "center" }}
          >
            <Button
              onClick={() => {
                props.closeMenu(false);
                window.open(routes.ORDER_ONLINE, "_blank");
              }}
              $width="100%"
              style={{ marginTop: "20px" }}
            >
              Order Online
            </Button>
          </motion.li>
        </ul>
      </motion.div>
    </>
  );
};

export default MenuSlider;
