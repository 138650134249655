import React from "react";
import videoUrl from "../../../assets/bannerBgVideo.mp4";
import styles from "./style.module.scss";

const BannerVideoContainer = () => {
  return (
    <div className={styles.videoContainer}>
      <video
        autoPlay
        muted
        playsInline
        loop
        src={videoUrl}
        style={{ width: "100vw", height: "854.438px" }}
      ></video>
    </div>
  );
};

export default BannerVideoContainer;
