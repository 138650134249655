import React from 'react';
import styles from "./style.module.scss";
import { SectionTitle, Text, Button } from '../../styledComponent';

const PromotionCard = (props) => {
  return (
    <div className={styles.promotionCard}>
        <SectionTitle>{props.title}</SectionTitle>
        <Text>{props.description}</Text>
        <Button>{props.cta}</Button>
    </div>
  )
}

export default PromotionCard;