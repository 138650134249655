import React from "react";
// import React, { useRef } from "react";
import { useFormik } from "formik";
// import ReCAPTCHA from "react-google-recaptcha";
import styles from "./style.module.scss";
import { franchiseFormInitialValues } from "../../../utils/formik/initialvalue";
import { franchiseFormValidationSchema } from "../../../utils/formik/validationSchema";
import { Button } from "../../styledComponent";

const FranchiseForm = () => {
  // const recaptchaRef = useRef();
  const formik = useFormik({
    initialValues: franchiseFormInitialValues,
    validationSchema: franchiseFormValidationSchema,
  });

  return (
    <div className={styles.formBlock}>
      <form
        // onSubmit={formik.handleSubmit}
        method="POST"
        name="franchiseForm"
        className="franchiseForm"
        netlify
        data-netlify="true"
        data-netlify-recaptcha="true"
      >
        <input type="hidden" name="form-name" value="franchiseForm" />
        <div className={styles.fieldContainer}>
          <input
            type="text"
            {...formik.getFieldProps("name")}
            placeholder="Name*"
            style={
              formik.touched.fullName && formik.errors.fullName
                ? { borderColor: "red" }
                : null
            }
          />
          <input
            type="text"
            {...formik.getFieldProps("phone")}
            placeholder="Phone Number*"
            style={
              formik.touched.phone && formik.errors.phone
                ? { borderColor: "red" }
                : null
            }
          />
        </div>
        <div className={styles.fieldContainer}>
          <input
            type="email"
            {...formik.getFieldProps("email")}
            placeholder="Email*"
            style={
              formik.touched.email && formik.errors.email
                ? { borderColor: "red" }
                : null
            }
          />
        </div>
        <div className={styles.fieldContainer}>
          <input
            type="text"
            {...formik.getFieldProps("Any_experience_in_food_industry")}
            placeholder="Any experience in food industry ?*"
            style={
              formik.touched.Any_experience_in_food_industry &&
              formik.errors.Any_experience_in_food_industry
                ? { borderColor: "red" }
                : null
            }
          />
        </div>
        <div className={styles.fieldContainer}>
          <input
            type="text"
            {...formik.getFieldProps("have_you_ever_owned_a_restaurant_before")}
            placeholder="Have you ever owned or run a restaurant before ?*"
            style={
              formik.touched.have_you_ever_owned_a_restaurant_before &&
              formik.errors.have_you_ever_owned_a_restaurant_before
                ? { borderColor: "red" }
                : null
            }
          />
        </div>
        {/* <div className={`${styles.fieldContainer} ${styles.recaptchaContainer}`}>
          <ReCAPTCHA
            ref={recaptchaRef}
            onChange={(value) => {
              formik.setFieldValue("recaptcha", value);
            }}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          />
        </div> */}
        <div className={styles.fieldContainer}>
          <Button
            className={
              !(formik.isValid && formik.dirty) ? "disabledBtn" : "submitBtn"
            }
            type="submit"
          >
            SUBMIT
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FranchiseForm;
