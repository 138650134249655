export const contactFormInitialValues = {
  name: "",
  email: "",
  message: "",
  // recaptcha: "",
};

export const franchiseFormInitialValues = {
  name: "",
  phone: "",
  email: "",
  Any_experience_in_food_industry: "",
  have_you_ever_owned_a_restaurant_before: "",
//   recaptcha: "",
};

export const reservationFormInitialValues = {
  date: "",
  time: "",
  number_of_members: "",
  name: "",
  email: "",
  phone: "",
//   recaptcha: "",
};

export const cateringFormInitialValues = {
  date: "",
  time: "",
  number_of_members: "",
  name: "",
  email: "",
  phone: "",
  message: "",
//   recaptcha: "",
};
