import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SectionTitle } from "../../components/styledComponent";

const PageNotFound = () => {
  const navigate = useNavigate();
  useEffect(() => navigate("/"), [navigate]);
  return (
    <div className="sectionContainer">
      <div style={{ paddingBottom: "10rem" }}>
        <SectionTitle $textAlign="center">Sorry, Page not found</SectionTitle>
      </div>
    </div>
  );
};

export default PageNotFound;
