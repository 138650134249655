import React from "react";
import { PageBanner } from "../../components/parts/section";
import styles from "./style.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import { SectionTitle, Text } from "../../components/styledComponent";
import ChickenSeekhImg from "../../assets/chickenSeekh.jpg";
import BeefSeekhKebab from "../../assets/beef_seekh_kebab.jpg";
import PunjabiSamosa from "../../assets/punjabi_samosa.jpg";
import ChickenSamosa from "../../assets/chicken_samosa.jpg";
import BeefSamosa from "../../assets/beef_samosa.jpg";
import TandooriMasala from "../../assets/tandoori_masala_soya.jpg";
import TandooriMalayi from "../../assets/tandoori_malai.jpg";

const BilloosCorporate = () => {
  return (
    <div className={styles.aboutPage}>
      <PageBanner title={"Billoos Corporate"} />

      {/* About First Section */}
      <div className={styles.aboutFirstSection}>
        <Container className={`sectionContainer`}>
          <Row>
            <Col>
              <Text>
                You will see us soon at your favourite local grocer or retailer
                as we expand our market to bring our Grab & Go products closer
                to you.
                <br />
                <br />
                Our journey began in 2017 with Billoo’s Indian Cuisine , a
                stand-alone restaurant in the Calgary Market area of Alberta.
                The restaurant is still there today and serving to the community
                and bring the smile with the essence of their food taste . The
                Restaurant has all strong following due to great menus,
                delicious food and dedicated, highly capable staff. That’s what
                brings us to where we are today.
                <br />
                <br />
                Billoo’s Foods Products – Ready to Eat foods made in strict
                quality control in our dedicated manufacturing units located in
                Calgary . We developed these food products keeping in mind the
                increasing demand. We prepare them to cater to the community, so
                that they can get quality foods over the counters at affordable
                price. We kindle their taste buds by supplying the following
                products.
              </Text>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Corporate Product */}
      <Container className={`sectionContainer ${styles.corporateProduct}`}>
        <Row>
          <Col lg={4}>
            <img src={ChickenSeekhImg} alt="product" />
            <SectionTitle>CHICKEN SEEKH KABAB</SectionTitle>
          </Col>
          <Col lg={4}>
            <img src={BeefSeekhKebab} alt="product" />
            <SectionTitle>BEEF SEEKH KABAB</SectionTitle>
          </Col>
          <Col lg={4}>
            <img src={PunjabiSamosa} alt="product" />
            <SectionTitle>PUNJABI SAMOSA</SectionTitle>
          </Col>
          <Col lg={4}>
            <img src={ChickenSamosa} alt="product" />
            <SectionTitle>CHICKEN SAMOSA</SectionTitle>
          </Col>
          <Col lg={4}>
            <img src={BeefSamosa} alt="product" />
            <SectionTitle>BEEF SAMOSA</SectionTitle>
          </Col>
          <Col lg={4}>
            <img src={TandooriMasala} alt="product" />
            <SectionTitle>TANDOORI MASALA SOYA CHAAP</SectionTitle>
          </Col>
          <Col lg={4}>
            <img src={TandooriMalayi} alt="product" />
            <SectionTitle>TANDOORI MALAI SOYA CHAAP</SectionTitle>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BilloosCorporate;
