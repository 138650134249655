import React from "react";
import { easeInOut, motion } from "framer-motion";
import { Container, Row, Col } from "react-bootstrap";
import {
  Facebook,
  Instagram,
  TelephoneFill,
  Envelope,
} from "react-bootstrap-icons";
import { SectionTitle, Text } from "../../components/styledComponent";
import { workingHours } from "../../utils/constants";
import { ContactForm } from "../../components/parts";
import styles from "./style.module.scss";
import { Helmet } from "react-helmet";
import { routes } from "../../utils/routes";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>
          Contact Billoos Restaurant | Indian Food Restaurant Downtown Calgary
        </title>
        <meta
          name="description"
          content="Billoos is one of the best Indian Food Restaurant in Calgary.Contact 403-770-6111 for book your table for family, friends and enjoy the Indian Cuisines."
        />
      </Helmet>
      <div className={styles.contactPage}>
        {/* Contact section start */}
        <section className={styles.contactSection}>
          <Container className={`sectionContainer`}>
            <Row>
              <Col lg={11} className={styles.contactBlock}>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ delay: 0.5, easings: easeInOut }}
                >
                  <p className={styles.smallTxt}>
                    Get Relax Any Day & Any Time
                  </p>
                  <SectionTitle>We'd Love to Hear From You</SectionTitle>
                  <ContactForm />
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ delay: 0.7, easings: easeInOut }}
                >
                  <SectionTitle $size="2.2rem">Working Hours</SectionTitle>
                  <div className={styles.timings}>
                    {workingHours &&
                      workingHours.map((data, i) => (
                        <div key={i}>
                          <Text>{data.day}</Text>
                          <Text>{data.timing}</Text>
                        </div>
                      ))}
                  </div>
                  <SectionTitle $size="2.2rem">Contact Details</SectionTitle>
                  <ul className={styles.contactDetailsBox}>
                    <li>
                      <a href="mailto:info@billoos.com">
                        <Envelope />
                        <span>info@billoos.com</span>
                      </a>
                    </li>
                    <li>
                      <a href="tel:4037706111">
                        <TelephoneFill />
                        <span>(403) 770-6111</span>
                      </a>
                    </li>
                  </ul>
                  <SectionTitle $size="2.2rem">Follow us</SectionTitle>
                  <div className={styles.followus}>
                    <a href={routes.FB} target="_blank" rel="noreferrer">
                      <Facebook size={25} />
                    </a>
                    <a href={routes.INSTAGRAM} target="_blank" rel="noreferrer">
                      <Instagram size={25} />
                    </a>
                  </div>
                </motion.div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Contact section end */}
      </div>
    </>
  );
};

export default Contact;
