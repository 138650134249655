export const routes = {
  HOME: "/",
  RESERVATION: "/reservation",
  CATERING: "/catering",
  BILLOOS_CORPORATE: "/billoos-corporate",
  ABOUT: "/about-us",
  CONTACT: "/contact-us",
  TAKE_OUT_MENU: "/take-out-menu",
  FB: "https://www.facebook.com/Billoos",
  TWITTER: "https://twitter.com/BilloosCuisine",
  INSTAGRAM: "https://instagram.com/billooscuisine",
  ORDER_ONLINE:
    "https://billoosindiancuisine.orderingclub.com/en/billoosindiancuisinesagehill0",
};
