import React from "react";
import { Helmet } from "react-helmet";
import { easeInOut, motion } from "framer-motion";
import { Container, Row, Col } from "react-bootstrap";
import { SectionTitle } from "../../components/styledComponent";
import { ReservationForm } from "../../components/parts";
import styles from "./style.module.scss";
import { LineDivider } from "../../components/ui";

const Reservation = () => {
  return (
    <>
      <Helmet>
        <title>
        Book A Table | East Indian Restaurants in Calgary NW
        </title>
        <meta
          name="description"
          content="Book your Table online at Billoo's Restaurant Calgary. Limited seats, so hurry-up book your table for family, friends and enjoy the Indian Cuisines."
        />
      </Helmet>
      <div className={styles.contactPage}>
        {/* Contact section start */}
        <section className={styles.contactSection}>
          <Container fluid className={`sectionContainer ${styles.reserveInnerSection}`}>
            <Row>
              <Col lg={12} className={styles.contactBlock}>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ delay: 0.5, easings: easeInOut }}
                >
                  <p className={styles.smallTxt}>
                    Get Relax Any Day & Any Time
                  </p>
                  <SectionTitle>Book a Table</SectionTitle>
                  <LineDivider />
                  <ReservationForm />
                </motion.div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};

export default Reservation;
